<template>

	<div class="container">
		<div class="search">
			<el-button type="primary" @click="dialogVisible = true;">新增类型</el-button>
		</div>
		<el-table :data="tableData" stripe>
			<el-table-column prop="panel_name" label="面板名称" />
			<el-table-column prop="type" label="类型" />
			<el-table-column prop="scenes" label="使用场景" width="70" />
			<el-table-column prop="type_name" label="类型名称" />
			<el-table-column prop="create_time" label="创建时间">
				<template #default="scope">
					{{timeToDate(scope.row.create_time)}}
				</template>
			</el-table-column>
			<el-table-column prop="update_time" label="修改时间">
				<template #default="scope">
					{{timeToDate(scope.row.update_time)}}
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template #default="scope">
					<el-button size="small" type="primary"><router-link :to='"/iot/rn/versions?panel_type="+scope.row.type'>查看版本</router-link></el-button>
					<el-button size="small" type="primary"><router-link :to='"/iot/rn/upload?panel_type="+scope.row.type'>上传</router-link></el-button>
					<el-button size="small" @click="showDistributeModels(scope.row.type)" :type="scope.row.scenes != 'device' ? 'inf' :'primary'"
						:disabled="scope.row.scenes != 'device'">型号</el-button>
				</template>

			</el-table-column>
		</el-table>
		<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :page-size="search.rows" :current-page="search.page" :total="search.total" />
	</div>

	<el-dialog v-model="dialogVisible" title="增加类型" center>
		<el-form :model="formData" label-width="120px">

			<el-form-item label="使用场景">
				<el-select v-model="formData.scenes" clearable>
					<el-option label="APP" value="app" />
					<el-option label="Device" value="device" />
				</el-select>
				<lable style="color: red;">选择设备时，上传面板时需要选择匹配型号</lable>
			</el-form-item>
			<el-form-item label="面板名称">
				<el-input v-model="formData.panel_name" placeholder="面板名称,仅用于显示" />
			</el-form-item>
			<el-form-item label="面板Code">
				<el-input v-model="formData.type" placeholder="类型唯一码">
					<template #prepend>{{formData.scenes+"_"}}</template>
				</el-input>
				<lable>需要全局<span style="color: red;">唯一</span>; &emsp; 使用场景为APP时，前缀为 `app_`; &emsp;使用场景为Device时，前缀为 `device_`; </lable>
			</el-form-item>
			<el-form-item label="面板类型名称">
				<el-input v-model="formData.type_name" placeholder="面板类型名称,仅用于显示" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="dialogVisible = false">取消</el-button>
				<el-popconfirm title="请确认你提交的数据,提交后无法修改?" @confirm="submitForm">
					<template #reference>
						<el-button type="primary">提交</el-button>
					</template>
				</el-popconfirm>

			</span>
		</template>
	</el-dialog>


	<el-dialog v-model="distributeVisible" width="40%" title="分配型号">
		<template #title>
			<h1> 分配型号：{{ distributeForm.type }}</h1>
		</template>
		<div class="distribute_box">
			<el-transfer v-model="checkedModels" filterable filter-placeholder="查找型号" :props="{ key: 'code', label: 'code'}" :data="models" :titles='["未选择型号","已选中"]' :button-texts='["取消","选中"]'
				@change="changeDistributeModels" width="100%" />
		</div>

		<template #footer>
			<el-button @click='distributeVisible=false'>取消</el-button>
			<el-button type="primary" @click="submitDistributeModels">提交</el-button>
		</template>
	</el-dialog>
</template>

<script>
	export default {
		components: {},
		name: 'RnTypes',
		mixins: [],
		data() {
			return {
				tableData: [],
				dialogVisible: false,
				formData: {
					scenes: "app",
					panel_name: "",
					type: "",
					type_name: "",
				},
				search: {
					rows: 10,
					page: 1,
					total: 0,
				},
				models: [],
				checkedModels: [],
				distributeVisible: false,
				distributeForm: {}
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			submitForm: function() {
				this.formData.type = this.formData.scenes + "_" + this.formData.type;
				this.api.RnPanelTypeCreate(this.formData).then(res => {
					if (res.data.code != 200) {
						this.$message.error('提交失败:' + res.data.msg)
						return
					}
					this.$message.success("提交成功");
					this.getList();
				});

			},
			getList: function() {
				this.api.RnPanelTypes(this.search).then(res => {
					if (res.data.code == 200) {
						this.tableData = res.data.data.data;
						this.search.total = res.data.data.page_info.total;
						return
					}
				})
			},
			handleCurrentChange: function(val) {
				this.search.page = val;
				this.getList();
			},
			showDistributeModels: function(type) {
				this.api.RnPanelTypeModels({
					type: type
				}).then(res => {
					if (res.data.code != 200) {
						this.$message.error('获取型号失败:' + res.data.msg)
						return
					}
					if(res.data.data){
						this.checkedModels = res.data.data;
					}
				})

				this.api.ProductModelList({
					page: 1,
					limit: 1000
				}).then(res => {
					if (res.data.code != 200) {
						this.$message.error('获取型号失败:' + res.data.msg)
						return
					}

					this.distributeForm = {
						type: type
					};
					this.distributeVisible = true;
					this.models = res.data.data.data;
				})
			},
			changeDistributeModels: function() {
				var models = [];
				for (const v of this.checkedModels) {
					models.push(v);
				}
				this.distributeForm.models = models;
			},
			submitDistributeModels: function() {
				this.api.RnPanelTypeDistribute(this.distributeForm).then(res => {
					if (res.data.code != 200) {
						this.$message.error('分配型号失败:' + res.data.msg)
						return
					}
					this.$message.success('分配型号成功')
				})
			}
		},
	}
</script>

<style>
	.distribute_box {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>